import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useState } from "react";
// import "./../../pages/portfolio/port.css";

const MyVerticallyCenteredModal = ({ object }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <button
        className="ab mt-2"
        onClick={() => {
          setModalShow(true);
        }}
      >
        {object.client}
      </button>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {object.client}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="swi">
        <Swiper
        style={{
          "--swiper-navigation-color": "#631a49",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs]}
        // className="mySwiper3"
      >

        {object.work.map((e)=>{
            return(<div key={e}>
                <SwiperSlide>
                <img src={e} alt="point blank"/>
              </SwiperSlide>
            </div>)
        }
        )}
      </Swiper>
        </div>
        </Modal.Body>

        <Modal.Footer>
        <button className="ab" onClick={()=>{setModalShow(false)}}>close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MyVerticallyCenteredModal;
