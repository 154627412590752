import React from 'react'
import Hero from '../../components/hero'
import AboutSection from '../../components/aboutSection';
import Portfolio from './../../components/portfolio';
import Blog from './../../components/blog';
import Counter from './../../components/counter';
import Video from './../../components/video';
import { useEffect } from 'react';
import Partner from '../../components/partner';





const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

        {/* hero sectio */}
        <Hero/>
        <div style={{marginTop:"40px"}}><AboutSection title="About Us"/></div>
        <Portfolio/>
        <Counter/>
        <Video/>
        <Blog/>
        <Partner/> 
        
        

    </>
  )
}

export default Home