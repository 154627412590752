import React from "react";

const Counter = () => {
  return (
    <section className="tf-section section-counter">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-top wow fadeInDown">
              <h3 className="title">
              Have A Quick Glimpse at Our Work
              </h3>
            </div>
          </div>
        </div>
        {/* <!-- /.row --> */}
      </div>
    </section>
  );
};

export default Counter;
