import React from "react";
import './partials/btn.css'

import about from "./../assets/images/about-us.jpg"
import { Link } from 'react-router-dom';
const AboutSection = (props) => {
  return (
    <section className="tf-section section-about" id="about">
      <div className="container">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-md-12 col-lg-5 d-flex flex-column justify-content-start">
            <div
              className="intro-content wow fadeInDown"
              data-wow-delay="400ms"
            >
              <h4 className="sub-title lineAB">{props.title}</h4>
              <div><h3 className="title" style={{padding:"80p 0"}}>
                <span>
                We offer packages of innovative 360
                marketing solutions
                </span>
              </h3>
              
              <p className="text ">
                Our distinguished team of marketers and designers are here to
                help you grow your business and boost your brand in the most
                appealing way!
              </p></div>
            </div>
            {props.title === 'About Us'?<div className=" wow fadeInLeft" data-wow-delay="400ms">
              <Link to="/about-us">
                <button className="ab"> See More</button>
              </Link>
            </div>:""}
          </div>
          <div className="col-md-12 col-lg-7 p-5" id="abt">
            <div className="image-about">
              <img src={about} alt="aboutme" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
