import React from "react";

const Pricing = () => {
  return (
    <section className="tf-section section-pricing pt-5">
      <div className="container">
        <div className="row padding-bottom-60">
          <div className="col-6 m-auto text-center">
            <div className="description-right">
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="list-pricing">
            <div className="themesflat-pricing wow fadeInUp">
              <div className="pricing-item">
                <div className="inner">
                  <div className="thumb data-effect-item">
                    <div
                      className="sale-off up-down"
                      style={{ background: "#9f2161" }}
                    ></div>
                    <h2 className="price text-dark">Our Vision</h2>

                    <hr />
                    <ul className="list-table">
                      <li>
                        <p
                          className="text-start"
                         
                        >
                          To become pioneers in bringing companies and customers
                          together, offer creative 360 marketing solutions plans
                          to help our clients increase their brand awareness,
                          customers’ reach and engagement, their return on
                          investment, and present themselves as successful
                          models in the market.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.themesflat-pricing --> */}

            <div
              className="themesflat-pricing wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="pricing-item">
                <div className="inner">
                  <div className="thumb data-effect-item">
                    <div
                      className="sale-off up-down"
                      style={{ background: "#9f2161" }}
                    ></div>
                    <h2 className="price text-dark">Our Mission</h2>

                    <hr />
                    <ul className="list-table">
                      <li>
                        <p
                          className="text-start"
                          
                        >
                          To utilize our powerful marketing team and tools to
                          make sure that your mere representation of thoughts is
                          given real vision. If you have a business, we are
                          close!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.themesflat-pricing --> */}

            <div
              className="themesflat-pricing wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="pricing-item">
                <div className="inner">
                  <div className="thumb data-effect-item">
                    <div
                      className="sale-off up-down"
                      style={{ background: "#9f2161" }}
                    ></div>
                    <h2 className="price text-dark">Our Values</h2>

                    <hr />
                    <ul className="list-table">
                      <li>
                        <h3 >Commitment</h3>
                        <p
                          className="text-start"
                          
                        >
                          We are pledged to give our time and energy to all our
                          clients and to deliver all our work with high quality.
                        </p>
                      </li>
                      <li>
                        <h3>Knowledge Development</h3>
                        <p className="text-start">
                          Everyday, we learn and develop our knowledge as
                          individuals, as a team, and as an agency.
                        </p>
                      </li>
                      <li>
                        <h3>Teamwork</h3>
                        <p className="text-start">
                          We encourage the sense of belonging and team spirit
                          between all our employees, which often translates to a
                          greater sense of ownership and accountability for the
                          work.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.themesflat-pricing --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
