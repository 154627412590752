import "./stylesheet/reset.css"
import "./stylesheet/shortcodes.css"
import"./stylesheet/style.css"
import"./stylesheet/responsive.css"
import"./stylesheet/animsition.css"
import"./stylesheet/animate.css"
import"./stylesheet/icomoon.css"
import "swiper/css/bundle";
import './App.css';

import { BrowserRouter, Route, Routes } from "react-router-dom"
// import NavBar from './components/navBar';
import Home from './pages/home/home';
import Footer from './components/footer';
import Services from './pages/services/services';
import ServiceDetails from './pages/serviceDetails/serviceDetails';
import services from './pages/data';
import About from './pages/about/about';
import PbcWork from './pages/portfolio/pbcWork';
import Contact from './pages/contact/contact';
import Nav2 from "./components/nav2"



function App() {

  
  return (
    <>

      <BrowserRouter>
        <div style={{zIndex:"9999"}}><Nav2/></div>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services services={services}/>} />
          <Route path='/services/:id' element={<ServiceDetails services={services}/>} />
          <Route path='/about-us' element={<About/>}/>
          <Route path='/portfolio' element={<PbcWork/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
          

        </Routes>
         
        

        <Footer />

      </BrowserRouter>


    </>
  );
}

export default App;
