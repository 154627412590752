import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper";
import "swiper/css/effect-cards";

import onGroundInfo from "../onGround";

const ServiceDetails = (props) => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {props.services.map((s) => {
        return (
          s.id === id && (
            <div key={s.id}>
              <section className="page-title srvcdetails" key={s.id}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-page">
                        <h2 className="title wow fadeInDown">
                          <span>{s.title}</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <!-- Project Detail --> */}

              <section
                className="tf-section section-project-details secpadd"
                key={s.tilte}
              >
                <div className="container">
                  <div className="row">
                    <div className="main-project-details">
                      <div className="project-content">
                        {s.id === "On-Ground-Activations" ? 
                          <Swiper
                            navigation={true}
                            effect={"cards"}
                            grabCursor={true}
                            modules={[EffectCards, Navigation]}
                            className="mySwiper"
                          >
                            {onGroundInfo.map((i) => {
                              return (
                                <div key={i.title} className="p-2 swipdiv">
                                  <SwiperSlide>
                                    <h1 className="title titlediv">
                                      {i.title}
                                    </h1>
                                    <img
                                      src={i.imgUrl1}
                                      alt="sw"
                                      style={{ width: "100%", height: "auto" }}
                                    />
                                  </SwiperSlide>
                                </div>
                              );
                            })}
                          </Swiper>
                         : 
                          <div key={s.title}>
                            <p className="wow fadeInUp">{s.desc}</p>

                            <div className="img-detail">
                              <img className="img-dt" src={s.imgUrl2} alt="" />
                              <img className="img-dt" src={s.imgUrl3} alt="" />
                            </div>
                          </div>
                        }
                      </div>

                      <div className="slidebar">
                        <div className="box-author wow fadeInUp">
                          <ul className="list-social">
                            <li>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.instagram.com/pbcegy/"
                              >
                                <FaInstagram style={{ fontSize: "25px" }} />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://eg.linkedin.com/company/pbcegy"
                              >
                                <FaLinkedin style={{ fontSize: "25px" }} />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.facebook.com/pbcegy/"
                              >
                                <FaFacebook style={{ fontSize: "25px" }} />
                              </a>
                            </li>
                          </ul>
                        </div>

                        {/* categeory */}
                        <div className="box-category wow fadeInUp">
                          <h5 className="title">Category</h5>
                          <ul className="list-category">
                            {props.services.map((service) => {
                              return (
                                <li key={service.id}>
                                  <Link
                                    to={`/services/${service.id}`}
                                    key={service.id}
                                  >
                                    <span>{service.title}</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="msgMe">
                  <Link to="/contact-us" className="btn-main">
                    <button name="submit" className="sBtn ab">
                      <span className="more">
                        Subscribe
                        <span className="icon"></span>
                      </span>
                    </button>
                  </Link>
                </div>
              </section>
            </div>
          )
        );
      })}
    </>
  );
};

export default ServiceDetails;
