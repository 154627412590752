import React, { useEffect } from "react";
import Subscribe from "../../components/partials/subscribe";
import { FaInstagram, FaLinkedin, FaFacebook, FaPhone } from "react-icons/fa";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="page-title contacttitle">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-page">
                <h2 className="title wow fadeInDown">
                  <span>Contact Us</span>
                </h2>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section section-contact secpadd">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="themesflat-map-1">
                <iframe
                  width="600"
                  height="500"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=30.0500433,31.4654901&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  title="contactMap200"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row padding-top-120" id="msgMe">
            <div className="col-lg-4 col-md-5 wow fadeInLeft">
              <div className="info-contact">
                <div className="address">
                  <h5 className="title">Address</h5>
                  <span>
                  Villa 70 - Al Banafseg 8 - New Cairo 1 - Cairo, Egypt

                  </span>
                </div>
                <div className="telephone padding-top-70">
                  <h5 className="title">Telephone</h5>
                  <span>
                    <a href="tel:01118816000" style={{ color: "#777777" }}>
                      (+2) 0111-881-6000
                    </a>
                  </span>
                </div>
                <div className="email padding-top-70">
                  <h5 className="title">E-mail</h5>
                  <span>
                    <a
                      href="mailto:info@pbc-eg.com"
                      style={{ color: "#777777" }}
                    >
                      info@pbc-eg.com
                    </a>
                  </span>
                </div>
                <div className="social padding-top-70">
                  <h5 className="title">Social</h5>
                  <ul className="list-social">
                    <li>
                      <a href="https://www.instagram.com/pbcegy/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/pbcegy/">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="https://eg.linkedin.com/company/pbcegy">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="tel:+201118816000">
                        <FaPhone />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-7 wow fadeInRight"
              data-wow-delay="400ms"
            >
              <div className="themesflat-spacer clearfix"></div>
              <div className="form-contact">
                <h5 className="title text-center">Let Us Know</h5>
                {/* start form */}
                <Subscribe />
                {/* <!-- /.themesflat-contact-form --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
