 const WorkData = [
    {
        client: "Ahmed Tafkeer" ,
        work:[
            require('./../assets/images/work/ahmed tafkeer/1.jpeg'),
            require('./../assets/images/work/ahmed tafkeer/2.jpeg'),
            require('./../assets/images/work/ahmed tafkeer/3.jpeg')
        ],
    },

    {
        client: "Amr Badawy" ,
        work:[
            require('./../assets/images/work/amr badawy/4.jpeg'),
            require('./../assets/images/work/amr badawy/5.jpeg'),
            require('./../assets/images/work/amr badawy/6.jpeg'),
            require('./../assets/images/work/amr badawy/7.jpg')
        ],
    },

    {
        client: "Auto 21" ,
        work:[
            require('./../assets/images/work/auto 21/8.jpeg')
        ],
    },

    {
        client: "Betal" ,
        work:[
            require('./../assets/images/work/betal/9.jpeg'),
            require('./../assets/images/work/betal/10.jpeg')
        ],
    },
    {
        client: "Enjoy Nuts" ,
        work:[
            require('./../assets/images/work/enjoy nuts/11.jpeg'),
            require('./../assets/images/work/enjoy nuts/12.jpeg'),
            require('./../assets/images/work/enjoy nuts/13.jpeg'),
            require('./../assets/images/work/enjoy nuts/14.jpeg'),
        ],
    },
    {
        client: "Ghorab" ,
        work:[
            require('./../assets/images/work/ghorab/15.jpeg'),
            require('./../assets/images/work/ghorab/16.jpeg'),
            require('./../assets/images/work/ghorab/17.jpeg'),
        ],
    },
    {
        client: "Leisure Leagues" ,
        work:[
            require('./../assets/images/work/leisure leagues/18.jpeg'),
            require('./../assets/images/work/leisure leagues/19.jpeg'),
        ],
    },
    {
        client: "Mac" ,
        work:[
            require('./../assets/images/work/mac/20.jpeg'),
            require('./../assets/images/work/mac/21.jpeg'),
            require('./../assets/images/work/mac/22.jpeg'),
            require('./../assets/images/work/mac/23.jpeg'),
            require('./../assets/images/work/mac/24.jpeg'),
            require('./../assets/images/work/mac/25.jpeg'),
            require('./../assets/images/work/mac/26.jpeg'),
            require('./../assets/images/work/mac/27.jpg'),
        ],
    },
    {
        client: "Pouvana" ,
        work:[
            require('./../assets/images/work/pouvana/28.jpeg'),
            require('./../assets/images/work/pouvana/29.jpeg'),
            require('./../assets/images/work/pouvana/30.jpg'),
            require('./../assets/images/work/pouvana/31.jpg'),
            require('./../assets/images/work/pouvana/32.jpg'),
            require('./../assets/images/work/pouvana/33.jpg')
        ],
    },
    {
        client: "USPORTS" ,
        work:[
            require('./../assets/images/work/usports/34.jpeg'),
        ],
    },

]
export  {WorkData}