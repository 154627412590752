import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      {/* <!-- Main Footer --> */}
      <section className="tf-section section-main-footer">
    
    <div className="container z-index">
        <div className="row">
            <div className="list-footer">   
            
                
           
            
                <div className="col menues">
                <div className="widget-link v1">
                    <h6 className="widget-title">Who We Are</h6>
                    <ul className="list-wrap">
                        <li><Link to="/about-us">About Us</Link></li>

                        <li><Link to="/services">Our Services</Link></li>
                        <li><Link to="/services">Our Work</Link></li>

                    </ul>
                </div>
            
           
                <div className="widget-link v2">
                    <h6 className="widget-title">Support</h6>
                    <ul className="list-wrap">
                        <li><Link to="/">Get Started Us</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>

                    </ul>
                </div>
                </div>
                <div className="col-auto widget-about">
                    
                    <div className=" text-center">
              <div className="copyright">
                <p >
                  Copywright by <span style={{color:"#394045"}}>Point Black Communications</span> Rights
                  Reserved{" "}
                </p>
              </div>
            </div>
                                    </div>
           
        

   
         
                
            </div>
        </div>
    </div>
</section>
    </footer>
  );
};

export default Footer;
