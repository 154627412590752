// tv
import tv1 from "./../assets/images/covers/cover-tv.jpg";
import tv2 from "./../assets/reels/tv ads.jpg";
import tv3 from "./../assets/reels/tv ads 3.jpg";
import tv4 from "./../assets/images/covers/cover-tv2.jpg";
// radious
import rad1 from "./../assets/images/covers/cover-radio.jpg";
import rad2 from "./../assets/reels/radio ads.jpg";
import rad3 from "./../assets/reels/radio ads 2.jpg";
import rad4 from "./../assets/images/covers/cover-radio2.jpg";

// evt
import evt1 from "./../assets/images/covers/cover-event-management.jpg";
import evt2 from "./../assets/reels/events and concerts mang 2.jpg";
import evt3 from "./../assets/reels/events and concerts mang.webp";
import evt4 from "./../assets/images/covers/cover-event-management2.jpg";
// booth
import booth from "./../assets/images/covers/cover-booth.jpg";
import booth1 from "./../assets/reels/booths production.avif";
import booth2 from "./../assets/reels/booths production 2.jpg";
import booth4 from "./../assets/images/covers/cover-booth2.jpg";
// ong
import onG from "./../assets/images/covers/cover-onground.jpg";
import onG1 from "./../assets/reels/on ground activations 2.jpeg";
// import onG2 from "./../assets/images/on ground activations3.webp"
import onG3 from "./../assets/reels/on ground activations 3.webp";
import onG4 from "./../assets/images/covers/cover-onground2.jpg";

// digital marketting
import dm from "./../assets/images/covers/cover-digital-marketing.jpg";
import dm1 from "./../assets/reels/digital marketing 3.avif";
import dm2 from "./../assets/reels/digital marketing200.webp";
import dm4 from "./../assets/images/covers/cover-digital-marketing2.jpg";

// baranding
import brnd from "./../assets/images/covers/cover-branding.jpg";
import brnd1 from "./../assets/reels/branding 2.webp";
import brnd2 from "./../assets/reels/branding 3.jpg";
import brnd4 from "./../assets/images/covers/cover-branding2.jpg";
// (photo, vid) graphy
import pht from "./../assets/images/covers/cover-photographer.jpg";
import pht1 from "./../assets/reels/photography and videography 2.jpg";
import pht2 from "./../assets/reels/photography and videography  3.jpg";
import pht4 from "./../assets/images/covers/cover-photographer2.jpg";
// lead
import lead from "./../assets/images/covers/cover-lead-generation.jpg";
import lead1 from "./../assets/reels/lead generations 3.jpg";
import lead2 from "./../assets/reels/lead generations.jpg";
import lead4 from "./../assets/images/covers/cover-lead-generation2.jpg";
// spons
import spons from "./../assets/images/covers/cover-sponsorships.jpg";
import spons1 from "./../assets/reels/sponsorship 3.jpg";
import spons3 from "./../assets/images/covers/cover-sponsorships2.jpg";
import spons2 from "./../assets/images/covers/cover-sponsorships2.jpg";
// pakaging
import pkg from "./../assets/images/covers/cover-packdesign.jpg";
import pkg1 from "./../assets/reels/packaging.webp";
import pkg2 from "./../assets/reels/packaging 3.jpg";
import pkg4 from "./../assets/images/covers/cover-packdesign2.jpg";
// wep develeping
import web from "./../assets/images/covers/cover-web-development.jpg";
import web1 from "./../assets/reels/web development.jpg";
import web2 from "./../assets/reels/web development 3.jpg";
import web4 from "./../assets/images/covers/cover-web-development2.jpg";

const services = [
  {
    title: "TV Ads",
    desc: "Television advertisement is the process of creating and airing highly creative, attention-grabbing, entertaining and memorable commercials on television in order to convey a message that is promoting, and aiming to market, a product or service, and make it stick in the viewer’s mind. TV ads rely on multiple senses to reach viewers; this is why they often include music, visuals, and special effects. It is considered as one of the most effective and important part of the marketing mix for many companies, as it reaches and impacts a wide range of consumers.",
    imgUrl1: tv1,
    imgUrl2: tv2,
    imgUrl3: tv3,
    imgUrl4: tv4,
    headerUrl: "",
    id: "TV-Ads",
  },
  {
    title: "Radio Ads",
    desc: "Radio advertising is the process of buying commercials through a radio station to promote products or services to its listening audience. It is an effective advertising medium because it's 100% audio-based. The advertiser's message can resonate in the consumer's ears with proper voice acting and sound design. It is also considered as a very influential medium, cost-efficient, targeted, easy to track, flexible and immediate and a provision for a strong call to action, if done properly. Radio Ads have the opportunity to reach people on the go, giving advertisers an edge over competitors.",
    imgUrl1: rad1,
    imgUrl2: rad2,
    imgUrl3: rad3,
    imgUrl4: rad4,
    headerUrl: "",
    id: "Radio-Ads",
  },
  {
    title: "Events & Concerts Management",
    desc: "Event management includes a variety of functions for executing large-scale events, which might include concerts, conferences, launching events, trade shows, festivals, and ceremonies. It involves handling the overall logistics of the event, working with staff, and conducting the project management of the event as a whole. Additional duties might include managing the budget and the teams of people responsible for each function, as well as overseeing the execution of the event.",
    imgUrl1: evt1,
    imgUrl2: evt2,
    imgUrl3: evt3,
    imgUrl4: evt4,
    headerUrl: "",
    id: "Events-&-Concerts-Management",
  },
  {
    title: "Booths Production",
    desc: "As a marketing agency, our home is full of creative designers, as we understand the importance of attractive designs to leave a strong positive impression in the consumers’ minds. Fortunately, we have a harmonious combination between eye capturing designs and its quality. The design that consumers ultimately go for should make the best use of design elements to make an impact on the end user. By using clever themes that the consumers can play around and interact with, the booth, and therefore the brand will be noticed for its fun and creative way. People always remember what they interact with physically and mentally.",
    imgUrl1: booth,
    imgUrl2: booth1,
    imgUrl3: booth2,
    imgUrl4: booth4,
    headerUrl: "",
    id: "Booths-Production",
  },
  {
    title: "On Ground Activations",
    desc: "",
    imgUrl1: onG,
    imgUrl2: onG1,
    imgUrl3: onG3,
    imgUrl4: onG4,
    headerUrl: "",
    id: "On-Ground-Activations",
  },
  {
    title: "Digital Marketing",
    desc: "Digital marketing, also called online marketing, is the promotion and creation of campaigns for brands to connect with potential customers through any number of digital channels. It is a broad field, including attracting customers via email, content marketing, social media, and more. Its primary goal is to promote brand awareness and establish trust between the brand and the consumers. Also, as the clients delve deeper into the world of digital marketing, they can get leads or have a closed direct sale.",
    imgUrl1: dm,
    imgUrl2: dm1,
    imgUrl3: dm2,
    imgUrl4: dm4,
    headerUrl: "",
    id: "Digital-Marketing",
  },
  {
    title: "Web Development",
    desc: "Web development is the process of developing a World Wide Web for your business. Marketing nowadays faces a lot of reliability challenges around consumers, creating a website is a good deal to start building trust with your consumers. Not only that, websites help create brand awareness and showcase brands to prospective customers. It is the most reliable and basic way to establish an image, create identity and let the brand’s target audience know what it is and what it represents. A website provides reliable information to the consumers, which helps in setting the business apart from the competitors. At PBC, we also offer SEO services to rank on the first pages of search engines and increase sales.",
    imgUrl1: web,
    imgUrl2: web1,
    imgUrl3: web2,
    imgUrl4: web4,
    headerUrl: "",
    id: "Web-Development",
  },
  {
    title: "Branding",
    desc: "Branding is the process of giving a meaning to a specific organization, company, product or service by creating and shaping a brand in consumers' minds. This shaping process takes a lot of ideas, designs, symbols, themes, creative selling lines (slogans)... and more. All the above interplays together to form what we call in the marketing field, the brand identity. Effective branding helps companies differentiate themselves from their competitors and builds a loyal customer base. This means that customers expect that your tone of voice is the same throughout all marketing communications. It is fair to say that good branding is strategic, while marketing is tactical.",
    imgUrl1: brnd,
    imgUrl2: brnd1,
    imgUrl3: brnd2,
    imgUrl4: brnd4,
    headerUrl: "",
    id: "Branding",
  },
  {
    title: "Photography & Videography",
    desc: "We have a team of specialized photographers, videographers, photo stylists, and editors who capture engaging and visually appealing moments for advertising related purposes. Our services include people, places, events and things through the use of professional technical equipment that would portray your story as imagined. We ensure that our client’s desired results are obtained to meet standards and deadlines of the projects. Other duties include recommending creative ideas and solutions that would suit all platforms, achieve desired results and maintain a professional image.",
    imgUrl1: pht,
    imgUrl2: pht1,
    imgUrl3: pht2,
    imgUrl4: pht4,
    headerUrl: "",
    id: "Photography",
  },

  {
    title: "Lead Generation",
    desc: "This service is a fundamental part of any growing business's revenues. It is simply the process of getting people interested in a brand and gradually moving them through a pipeline to make them potential (paying) customers. We use all our marketing tools and approaches to attract, nurture, and deliver quality leads",
    imgUrl1: lead,
    imgUrl2: lead1,
    imgUrl3: lead2,
    imgUrl4: lead4,
    headerUrl: "",
    id: "Lead-Generation",
  },
  {
    title: "Sponsorships",
    desc: "",
    imgUrl1: spons,
    imgUrl2: spons1,
    imgUrl3: spons2,
    imgUrl4: spons3,
    headerUrl: "",
    id: "Sponsorships",
  },
  {
    title: "Packaging Design",
    desc: "Packaging design is the process that allows your packaging to fulfill a wide range of functions. Our designs are functional, holding the product, enabling it to be transported and stored. We will make sure that our packaging designs stand out from your competitors’ packaging in some way, as well as appeal to customers in-store and online.",
    imgUrl1: pkg,
    imgUrl2: pkg1,
    imgUrl3: pkg2,
    imgUrl4: pkg4,
    headerUrl: "",
    id: "Packaging-Design",
  },
];
export default services;
