import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./../../components/portfolio.css";
import "./services.css";

const Services = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title srvtitle">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-page">
                <h2 className="title wow fadeInUp">
                  <span>Services</span>
                </h2>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      
      {/* content */}
      <section className="tf-section section-portfolio style-2">
        <div className="container">
          <div className="row" id="msgMe">
            {props.services.map((s) => {
              return (
                  <div
                    className="list-portfolio col-auto col-md-4 m-auto mb-5"
                    key={s.id}
                  >
                    <div className="themesflat-portfolio wow fadeInUp">
                      <Link to={`/services/${s.id}`}>
                      <div className="project-item">
                        <div className="inner">
                          <div className="boxitem">
                          <div className="imgbox">
                          <img
                            src={s.imgUrl1}
                            alt="hamela"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <img className="img-overlay"
                            src={s.imgUrl4}
                            alt="hamela"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          
                        </div>
                            <div className="box-item-content">
                              <div className="content-box">
                                <h5 className="title">
                                  <span>
                                    {s.title}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>
                
              );
            })}
          </div>
        </div>

        
      </section>

      {/* pegination  */}
      {/* <div className="themesflat-spacer clearfix" data-desktop="65" data-mobile="65" data-smobile="40"></div>
                <div className="row">
                    <div className="col-12">
                        <div className="pagination-project">
                            <ul className="list-pagination">
                                <li><a href="#"><i className="fas fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#"><i className="fas fa-angle-right"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
    </>
  );
};

export default Services;
