import tv from "./../assets/images/covers/cover-tv.jpg";
import tv2 from "./../assets/images/covers/cover-tv2.jpg";
import digital from "./../assets/images/covers/cover-digital-marketing.jpg";
import digital2 from "./../assets/images/covers/cover-digital-marketing2.jpg";
import onGround from "./../assets/images/covers/cover-onground.jpg";
import onGround2 from "./../assets/images/covers/cover-onground2.jpg";
import booth from "./../assets/images/covers/cover-booth.jpg";
import booth2 from "./../assets/images/covers/cover-booth2.jpg";
const fourService = [
  {
    title: "TV Ads",
    imgUrl1: tv,
    imgUrl2: tv2,
    id: "TV-Ads",
  },
  {
    title: "Booths Production",
    imgUrl1: booth,
    imgUrl2: booth2,
    id: "Booths-Production",
  },
  {
    title: "On Ground Activations",
    imgUrl1: onGround,
    imgUrl2: onGround2,
    id: "On-Ground-Activations",
  },
  {
    title: "Digital Marketing",
    imgUrl1: digital,
    imgUrl2: digital2,
    id: "Digital-Marketing",
  },
];
export default fourService;
