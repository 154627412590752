import React from "react";
import { Link } from "react-router-dom";
import fourService from "./../pages/fourServices";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <section className="tf-section section-portfolio no-padding-top">
      <div className="container">
        <div className="row padding-bottom-60" id="serv">
          <div className="col-md-8">
            <div
              className="heading-top wow fadeInDown"
              style={{ paddingTop: "40px" }}
              data-wow-delay="400ms"
            >
              <h4 className="sub-title lineSER">Our Services</h4>

            </div>
          </div>
          
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/* <!-- /.themesflat-portfolio --> */}
          {fourService.map((e) => {
            return (
              
                <div className="list-portfolio col-auto col-md-4 col-lg-3  p-2 m-auto" key={e.id}>
                <Link to={`/services/${e.id}`}>
                <div
                  className="themesflat-portfolio wow fadeInUp "
                  data-wow-delay="400ms"
                  key={e.id}
                >
                  <div className="project-item">
                    <div className="inner">
                      <div className="boxitem">
                        <div className="imgbox">
                          <img
                            src={e.imgUrl1}
                            alt="hamela"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <img className="img-overlay"
                            src={e.imgUrl2}
                            alt="hamela"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          
                        </div>

                        <div className="box-item-content">
                          <div className="content-box">
                            <h5 className="title " style={{textDecoration:"none"}}>
                              {e.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
                </div>
              
            );
          })}
        </div>
      </div>

      <div className="view-portfolio">
        <Link to="/services">
          <button className="ab">See More</button>
        </Link>
      </div>
    </section>
  );
};

export default Portfolio;
