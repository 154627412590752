import gorm from "./../assets/reels/onground/Guerilla Projection 3.jpg"
import gorp from "./../assets/reels/onground/Guerilla Marketing.jpg"
import exp from "./../assets/reels/onground/Experiential Events3.jpg"
import str from "./../assets/reels/onground/Street team Marketing.jpg"
import comm from "./../assets/reels/onground/Community Events.jpg"
import ret from "./../assets/reels/onground/Retail Activations 2.jpg"
import brand from "./../assets/reels/onground/Brand Activations.jpg"
import muralAds from "./../assets/reels/onground/Street Mural Advertising 3.jpg"
import popup from "./../assets/reels/onground/Mobile Pop Up Shop 2.jpg"
import human from "./../assets/reels/onground/Human Billboard.jpg"
import bic from "./../assets/reels/onground/Bicycle Billboard 3.jpg"






const onGroundInfo = [
    {
        title: "Guerilla Marketing",
        desc: "Guerrilla marketing is a marketing tactic in which a company uses surprise and/or unconventional interactions in order to promote a product or service. It is different from traditional marketing as it often relies on personal interaction, has a smaller budget, and focuses on smaller groups of promoters. The best guerilla marketing concepts are those that people gravitate towards, want to engage with, and share with friends. Its approaches remain a rise due to their ability to break through the clutter, attract consumers, drive organic social media, and provide valuable brand content.",
        imgUrl1: gorm,
        headerUrl: "",
        id: "Guerilla-Marketing",
      },
      {
        title: "Guerilla Projection",
        desc: "Guerilla projection advertising is the large-scale display of video content on the exterior of buildings to create thousands of impactful brand impressions nightly. Guerilla projection outdoor advertising is also a widely used tactic to create a stand-out presence during conventions and citywide events.",
        imgUrl1: gorp,
        headerUrl: "",
        id: "Guerilla-Projection",
      },
      {
        title: "Experiential Events",
        desc: "Experiential events seek to immerse people in an experience. It is a marketing strategy that invites an audience to interact with a business in a real-world situation. It's an effective way to show customers not just what the company offers, but what it stands for.",
        imgUrl1: exp,
        headerUrl: "",
        id: "Experiential-Events",
      },
      {
        title: "Street team Marketing",
        desc: "Street Team Advertising is when individuals interact with the public with the sole purpose of promoting a product, service or event. Street team marketing builds brand awareness, generates buzz, and increases market visibility and it is considered as an extremely cost-effective method since thousands of people can be reached in a short time period.",
        imgUrl1: str,
        headerUrl: "",
        id: "Street-team-Marketing",
      },
      {
        title: "Community Events",
        desc: "We manage all kinds of community events for all kinds of communities and groups. These events are an excellent way of getting communities together and socializing with one another. This in turn can benefit community relations and create a positive impression for the companies that may be booking these events for their communities.",
        imgUrl1: comm,
        headerUrl: "",
        id: "Community-Events",
      },
      {
        title: "Retail Activations",
        desc: "Retail events are in-person events hosted by brands to create a memorable shopping experience for customers. Customers are getting more selective about the brands they associate with, and the in-store experience specific brands stand out from the crowd.",
        imgUrl1: ret,
        headerUrl: "",
        id: "Retail-Activations",
      },
      {
        title: "Brand Activations",
        desc: "Brand activation refers to a campaign, event, or interaction through which your brand generates awareness and builds lasting connections with your target audience. Most brand activations are interactive, allowing audiences to engage directly with a brand and its products.",
        imgUrl1: brand,
        headerUrl: "",
        id: "Brand-Activations",
      },
      {
        title: "Street Mural Advertising",
        desc: "Street murals are one-of-a-kind, art-centric, street-level, hand-painted outdoor billboards on permitted wall space. It is a creative way to take on outdoor advertising, as it takes place large scale eye-catching paintings that are manually applied to city walls and buildings. Most advertising murals involve street artists who are hired to design and hand paint the mural onto a building.",
        imgUrl1: muralAds,
        headerUrl: "",
        id: "Street-Mural-Advertising",
      },
      {
        title: "Mobile Pop Up Shop",
        desc: "Mobile Pop Up Shop provides brands with the opportunity to connect with new audiences, create memorable experiences, and generate sales. It is known as showroom vehicles, glass wall trucks, retail trailers, portable experience rooms, and experiential mobile billboards. Also, it is considered as a unique element of one's retail strategy, as they allow brands to reach and transact in nontraditional environments.",
        imgUrl1: popup,
        headerUrl: "",
        id: "Mobile-Pop-Up-Shop",
      },
      {
        title: "Human Billboard",
        desc: "Human billboard teams are a noticeable, eco-friendly, street-level form of marketing. Human billboard is someone who applies an advertisement on their person. Most commonly, this means holding or wearing a sign of some sort, but also may include wearing advertising as clothing.",
        imgUrl1: human,
        headerUrl: "",
        id: "Human-Billboard",
      },
      {
        title: "Bicycle Billboard",
        desc: "Bicycle billboards are a creative and eco-friendly way to advertise your brand. It is a type of mobile advertising in which a bike tows a billboard with an advertising message. They are deployed in groups to create an undeniable outdoor presence. Riders outfitted in branded shirts, helmets, and jackets to unify the team and enhance brand impact.",
        imgUrl1: bic,
        headerUrl: "",
        id: "Human-Billboard",
      },
  ];
  export default onGroundInfo;
  