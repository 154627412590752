import React from "react";
import "./hero.css";
import heropic from "./../assets/images/her1.png";
import BtnMore from "./partials/btn";

const Hero = () => {
  return (
    <>
      <div className="hero">
        <div className="container-fluid  main-content">
          <div className="row align-items-center flex-row herpad">
            <div className="col-12 col-lg-6   hero-img  fadeInDown">
              <img src={heropic} alt="" className="up-down" />
            </div>
            <div className="col-12 col-lg-6  heroText">
              <h1 style={{ color: "white" }} className="pb-2">
                Your home for 360 Marketing Solutions!
              </h1>
              <p style={{ color: "white", fontSize: "16px" }} className="pb-2">
                We will put our marketing tools behind your idea, dream & brand
                to make a noise the world notices.
              </p>
              <div className=""><a href="#about">
                <BtnMore discover="Discover" more="More" />
              </a></div>
            </div>


            
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
