import React from 'react'
import './btn.css'

const BtnMore = (props) => {
 
  return (
    <button> See More
</button>
  )
}

export default BtnMore