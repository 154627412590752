import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { EffectFade } from "swiper";
import logo1 from "./../images/client/auto21.png";
import logo2 from "./../images/client/cerelac.png";
import logo3 from "./../images/client/cocacola.png";
import logo4 from "./../images/client/elnsagon.png";
import logo5 from "./../images/client/nestle.png";
import logo6 from "./../images/client/nido.png";
import logo7 from "./../images/client/nile-air.png";
import logo8 from "./../images/client/panadol.png";
import logo9 from "./../images/client/pizza-king.png";
import logo10 from "./../images/client/sea-taj.png";
import logo11 from "./../images/client/sensodyne.png";
import logo12 from "./../images/client/tran-quini.png";
import logo13 from "./../images/client/soltan.png";

import "swiper/css";
import "swiper/css/effect-fade";

const Partner = () => {
  return (
    <>
      <div  style={{background:"#f3f3f4"}}>
      <div className="container text-center " style={{ padding: "60px 0" }}>
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          autoplay={{
            delay: 500,
            disableOnInteraction: false,
          }}
          
          effect="coverflow"
          
          modules={[Autoplay, EffectFade]}
        >
          <SwiperSlide>
            <img src={logo1} alt="sw" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo2} alt="sw" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo3} alt="sw" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo4} alt="sw" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo5} alt="sw" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo6} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo7} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo8} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo9} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo10} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo11} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo12} alt="sw" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logo13} alt="sw" />
          </SwiperSlide>
        </Swiper>
      </div>
      </div>
    </>
  );
};

export default Partner;
