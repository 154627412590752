import React from "react";
import { FaPlay } from "react-icons/fa";

const Video = () => {
  return (
    <section className="tf-section section-video no-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-video wow fadeInUp" data-wow-delay="400ms">
              <div className="box">
                <div className="overlay"></div>
                <div className="flat-video-fancybox">
                  <a
                    className="fancybox"
                    data-type="iframe"
                    href="https://www.youtube.com/embed/QdXZQWu02bY?autoplay=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="vidPlay"><FaPlay/> </span>
                    <span className="ripple"></span>
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
