import React ,{useState}from 'react'
import  emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import './subscribe.css'



const Subscribe = () => {
    
        const [msg,setMsg]=useState(false)

        const navigate = useNavigate()
      
        const handleSubmit = (e)=>{
          e.preventDefault();
      
          emailjs.sendForm('service_vfrhodm', 'template_6qi04yp', e.target, 'EprWs0wYI1ocuBhCg')
            .then((result) => {
              setMsg(!msg);
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            }).then(setTimeout(()=>{navigate('/')},3000))
            
        }
    return (
        <>
            
            <div className="themesflat-contact-form style-2">
                <h5 className='title text-black-50 text-center'>Subscribe</h5>
                  <form id="contactform" className='pb-5' onSubmit={handleSubmit} style={{maxWidth:"600px",margin:"0 auto"}}>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="youremail@example.com"
                          name="from_name"
                          
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone"
                          aria-label="Last name"
                          name="from_phone"
                          
                        />
                      </div>
                    </div>
                    <div className="btn-main">
                      <button name="submit" type="submit"  id="submit" className='sbtn ab'>
                        <span className="more">
                          Send Message
                          
                        </span>
                      </button>
                    </div>
                  </form>
                  {msg && <div className="success">msg sent</div>}
                </div>
            
        </>
    )
}

export default Subscribe;