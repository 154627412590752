import React, { useEffect } from "react";
import AboutSection from "../../components/aboutSection";
import Pricing from "../../components/pricing";
import "./about.css"


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <section className="page-title bgtitle">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-page">
                <h2 className="title wow fadeInDown">
                  <span>About Us</span>{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
      <Pricing/>
      </div>
      <div style={{paddingTop:"60px"}}><AboutSection title={'Our Team'}/></div>
      
    </>
  );
};

export default About;
