import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import logo from "./../images/pbc-logo2.png";
import {Link} from "react-router-dom"



const Nav2 = () => {
  return (
    <nav className="navbar navbar-expand-lg  myCust">
  <div className="container-fluid">
    <a className="navbar-brand" href="/"><img
                    src={logo}
                    alt="pbc logo"
                    style={{ height: "40px", width: "auto" }}
                  /></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about-us">ABOUT US</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services">OUR SERVICES</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to='/portfolio'>OUR WORK</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to='/contact-us'>CONTACT US</Link>
        </li>
      </ul>
      <div className="d-flex gab-5" role="search">
        <div className="fb p-2"><a href="https://www.facebook.com/pbcegy/" style={{color:"white"}}><FaFacebook/></a></div>
        <div className="inst p-2"><a href="https://www.instagram.com/pbcegy/"style={{color:"white"}}><FaInstagram/></a></div>
        <div className="lnkd p-2"><a href="https://eg.linkedin.com/company/pbcegy"style={{color:"white"}}><FaLinkedin/></a></div>
      </div>
    </div>
  </div>
</nav>
  )
}
export  default Nav2