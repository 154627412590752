import React from "react";

const Blog = () => {
  return (
    <section className="tf-section section-blog no-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-top wow fadeInDown">
              <h4 className="sub-title padding-bottom-30 lineCL">Our Clients</h4>
              <p className="title">
              Working for the past years in the marketing industry,<br/> our clients have always been our partners in success 
                <br />
               
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Blog;
