import { useEffect} from "react";
import {WorkData} from "./../work";
import MyVerticallyCenteredModal from "../../components/modals/workmodal";
import "./port.css";

const PbcWork = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title portfoliotitle">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-page">
                <h2 className="title wow fadeInDown">
                  <span>Our Work</span>
                </h2>
                <div className="wow fadeInLeft" data-wow-delay="400ms"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container pt-4">
        <div className="row mt-5">
          {WorkData.map((object) => {
            return (
              <div className="list-portfolio col-auto col-md-4 col-lg-3  mb-5"
                key={object.client}>
                <div className="themesflat-portfolio wow fadeInUp">
                  <div className="project-item">
                    <div className="inner">
                      <div className="boxitem">
                        <div className="imgbox">
                          <img
                            src={object.work[0]}
                            alt="hamela"
                            className="portimg"
                          />
                          
                            <MyVerticallyCenteredModal
                            object={object}
                            />
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default PbcWork;
